<template>
  <div>
    <v-container fluid class="pa-6">
      <v-row>
        <v-col md="12">
          <v-card>
            <v-card-title>
              <h2 class="mb-0">{{$t('campaignsOutTitle')}}</h2>
            </v-card-title>
            <v-card-text>
              <DatatableV2 :displayTitle="false" :flat="true" :url="apiUrl" :datatableId="1" ref="campaignOut"/>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col md="12">
          <v-card>
            <v-card-title>
              <h2 class="mb-0">{{$t('campaignsInTitle')}}</h2>
            </v-card-title>
            <v-card-text>
              <DatatableV2 :displayTitle="false" :flat="true" :url="apiUrlIn" :datatableId="2" ref="campaignIn"/>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
  import DatatableV2 from '@/components/DatatableV2'

  export default {
    name:"EntityDetailCampaign",
    components: { DatatableV2 },
    data() {
      return {
        apiUrl: "",
        apiUrlIn: ""
      }
    },
    created() {
      this.apiUrl = "/entity/" + this.$route.params.entity_id + "/getCampaignOutList?type=CAMP_OUT_P"
      this.apiUrlIn = "/entity/" + this.$route.params.entity_id + "/getCampaignCallList?type=CAMP_CALL"
    },
  }
</script>